import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogDeleteGenericComponent } from '@app/shared/components/dialog-delete-generic/dialog-delete-generic.component';
import { ConnectedApp } from '@app/shared/interfaces/lib/connected-app.interface';
import { AppsService } from '@app/shared/services/apps/apps.service';
import { PosthogService } from '@app/shared/services/posthog/posthog.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'sbnb-connected-apps',
  templateUrl: './connected-apps.component.html',
})
export class ConnectedAppsComponent implements OnInit {
  apps: ConnectedApp[] = [];
  loading: boolean;

  integrations$ = this.appsService.integrations$;

  constructor(
    private appsService: AppsService,
    private dialog: MatDialog,
    private posthog: PosthogService
  ) { }

  ngOnInit(): void {
    this.loadConnectedApps();
  }

  loadConnectedApps() {
    this.loading = true;
    this.appsService.getConnectedApps().subscribe((apps: ConnectedApp[]) => {
      this.loading = false;
      this.apps = apps;
    });
  }

  hasDetailsPage(appCode: string): Observable<boolean> {
    return this.integrations$.pipe(
      map((integrations) =>
        integrations.some(
          (integration) => integration.vendorCode === appCode && integration.link && !integration.external_link
        )
      )
    );
  }

  deleteAppPrompt(app: ConnectedApp) {
    const dialogRef = this.dialog.open(DialogDeleteGenericComponent, {
      width: '720px',
      data: {
        name: app.name,
        action: 'Disconnect',
        additionalText:
          'Disconnecting this app will remove it from your account and the partner will no longer be able to access your data.',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteApp(app);
      }
    });
  }

  deleteApp(app: ConnectedApp) {
    this.appsService.deleteIntegration(app).subscribe((res) => {
      if (res) {
        const index = this.apps.findIndex((x: ConnectedApp) => x.code === app.code);

        if (index > -1) {
          this.apps.splice(index, 1);
        }
      }
    });
  }

  navigateToApp(link: string) {
    window.open(link, '_blank'); // Opens the link in a new tab
  }

  upgradeConnection(vendorCode: string) {
    this.appsService.upgradeConnection(vendorCode).subscribe(
      (response) => {
        // Refresh the list after successful upgrade
        this.loadConnectedApps();
      },
      (error) => {
        console.error('Error upgrading connection:', error);
      }
    );
  }
}
