<div fxLayout>
  <main fxFlex class="subnav__exists" [ngClass.lt-md]="{ mobile: true, subnav__exists: false }">
    <div class="flex" class="webhooks__header">
      <h2 class="flex items-center gap-3">
        <sbnb-icon class="clickable hidden sbnbDesktop:block" [routerLink]="['../']" icon="arrow-left-rose"></sbnb-icon>
        <sbnb-icon class="clickable block sbnbDesktop:hidden" [routerLink]="['../']" icon="left-arrow"></sbnb-icon>
        <span class="flex-1">Connected Apps</span>
      </h2>
    </div>
    <p class="text-gray-600 mb-4">Manage the apps that are connected to your Hospitable account.</p>

    <sbnb-loading-spinner *ngIf="loading"></sbnb-loading-spinner>

    <ng-container *ngIf="!loading">
      <sbnb-no-items
        *ngIf="(!loading && !apps) || apps.length === 0"
        icon="app"
        text="No connected apps found."
        subtext="Visit the apps page to see available integrations."></sbnb-no-items>

      <div *ngIf="apps && apps.length > 0" class="rules__container">
        <div
          *ngFor="let app of apps"
          class="flex flex-col border-smoke border rounded p-4 mb-4 h-full"
          data-testid="connected-app">
          <div class="flex justify-between items-center">
            <div>
              <h3 class="text-lg font-semibold mb-1">{{ app.name }}</h3>
              <span *ngIf="app.added_at_label" class="text-sm mb-2">Added: {{ app.added_at_label }}</span>
            </div>
            <img [src]="app.logo" alt="{{ app.name }} logo" class="h-16 w-16" />
          </div>
          <div class="flex items-center mt-2 flex-grow">
            <div *ngIf="app.scopes?.length">
              <span class="font-semibold">Has permission to:</span>
              <ul class="list-disc pl-5">
                <li class="mb-2" *ngFor="let scope of app.scopes">{{ scope }}</li>
              </ul>
            </div>
          </div>

          <ng-container *ngIf="app.connect">
            <div class="mt-4 w-full">
              <p class="mb-2">
                {{ app.name }} is connected to your Airbnb account through a limited connection. Upgrading to a full
                Hospitable integration will connect {{ app.name }} with your Hospitable account, giving them access to
                reservations from all of your booking channels and unlocking additional features of the integration.
              </p>
            </div>
          </ng-container>

          <div class="mt-4 flex justify-between w-full">
            <div>
              <button
                *ngIf="hasDetailsPage(app.code) | async"
                mat-flat-button
                class="button__secondary button__small"
                data-testid="view-app-button"
                (click)="navigateToApp('/apps/' + app.code)">
                View app
              </button>
            </div>

            <div class="flex gap-2">
              <ng-container *ngIf="app.connect">
                <button mat-flat-button class="button__secondary button__small" data-testid="read-more-button">
                  Read more
                </button>
                <button
                  mat-flat-button
                  class="button__primary button__small"
                  data-testid="upgrade-connection-button"
                  (click)="upgradeConnection(app.code)">
                  Upgrade Connection
                </button>
              </ng-container>

              <button
                *ngIf="!app.first_party && !app.connect"
                mat-flat-button
                class="button__primary button__small"
                data-testid="remove-access-button"
                (click)="deleteAppPrompt(app)">
                Remove access
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </main>
</div>
